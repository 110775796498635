import posthog from 'posthog-js'

export default defineNuxtPlugin(nuxtApp => {
  const { public: { POSTHOG_PUBLIC_KEY, POSTHOG_HOST, NODE_ENV } } = useRuntimeConfig()

  // Initialize PostHog
  const posthogClient = posthog.init(POSTHOG_PUBLIC_KEY, {
    api_host: POSTHOG_HOST,
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    capture_pageview: false, // we add manual pageview capturing below
  })
  if (!posthogClient) {
    console.error('[PostHog] Failed to initialize PostHog')
  }

  // Capture pageviews and pageleaves
  const router = useRouter()
  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture('$pageview', {
        current_url: to.fullPath
      })
    })
  })
  router.beforeEach((to, from) => {
    posthog.capture('$pageleave', {
      from_url: from.fullPath,
      to_url: to.fullPath
    })
  })

  // Provide the posthog client to the app
  return {
    provide: {
      posthog: posthogClient
    }
  }
})